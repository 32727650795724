import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DescriptiveProductModel } from "../../models/descriptive-product-model";

interface SoftwareState {
    data: DescriptiveProductModel[];
}

const initialState: SoftwareState = {
    data: []
};

const softwareSlice = createSlice({
    name: "labels",
    initialState,
    reducers: {
        addSoftware: (state: SoftwareState, data: PayloadAction<DescriptiveProductModel[]>) => {
            state.data = data.payload;
        }
    }
});

export const { addSoftware } = softwareSlice.actions;

export default softwareSlice.reducer;
