import './success-stories.css'
import { useEffect, useState } from 'react';
import { CustomerModel } from '../../../models/customer-model';
import { splitArray, fetchData } from '../../../services/utility-service';

function SuccessStories() {

    const [stories, setStories] = useState<CustomerModel[][]>([]);
    const [currentRowIndex, setCurrentRowIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    useEffect(() => {
        async function loadData() {
            const fileData =
                await fetchData<CustomerModel[]>(`${process.env.PUBLIC_URL}/data/success-stories.json`);

            const splitData = splitArray(fileData);

            setStories(splitData);
            setMaxIndex(splitData.length - 1);
        }

        loadData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            let oldIndex = currentRowIndex;
            const newIndex = oldIndex === maxIndex ? 0 : ++oldIndex;

            setCurrentRowIndex(newIndex);
        }, 5000);

        return () => clearInterval(interval);
    }, [currentRowIndex, maxIndex]);

    return (
        <div className="success-stories-wrapper">
            {
                stories.map((row, i) =>
                    <div className={`stories-row ${currentRowIndex === i ? 'show' : 'hide'}`} key={i}>
                        {
                            row.map((item, y) =>
                                <div className="story" key={y}>
                                    <img src={`${process.env.PUBLIC_URL}/${item.imageUrl}`} alt={item.name} />
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}


export default SuccessStories;