import { CategoryModel } from '../../../models/category-model';
import './category.css'
import { Navigate } from '../../shared';

interface CategoryProps {
    data: CategoryModel
}


const generatePageUrl = (category: CategoryModel): string => {
    if (!category.slug) {
        return `${process.env.PUBLIC_URL}/${category.pageUrl}`;
    }

    return `${process.env.PUBLIC_URL}/${category.pageUrl}/${category.slug}`;
}


function Category({ data }: CategoryProps) {
    return (
        <div className="category">
            <div className="category-inner">
                <div className="category-front">
                    <img src={`${process.env.PUBLIC_URL}/${data.imageUrl}`} alt={data.title} />

                    <h2>{data.title}</h2>
                </div>

                <div className="category-back">
                    <h2>{data.title}</h2>

                    <div className="category-description">{data.description}</div>

                    <Navigate to={generatePageUrl(data)}>
                        Explore

                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z" />
                        </svg>
                    </Navigate>
                </div>
            </div >
        </div>
    )
}

export default Category;