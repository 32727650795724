import { useDispatch, useSelector } from 'react-redux';
import { DescriptiveProduct, Hero, NavigationTree } from '../../components/shared';
import { NavigationLinkModel } from '../../models/navigation-link-model';
import './barcode-labels.css';
import { AppDispatch } from '../..';
import { RootState } from '../../redux/store';
import { selectLabels, selectTotalLabelsCount } from '../../redux/selectors/labels-selector';
import { useEffect } from 'react';
import { loadLabels } from '../../services/data-service';

const GenerateNavigationTreeLinks = (): NavigationLinkModel[] => {
    return [
        {
            title: 'Home',
            url: ''
        },
        {
            title: 'Barcode Labels',
            url: null
        }
    ] as NavigationLinkModel[];
}



function BarcodeLabels() {
    const dispatch = useDispatch<AppDispatch>();

    const labels =
        useSelector((state: RootState) => selectLabels(state));

    const totalLabelsCount = useSelector((state: RootState) => selectTotalLabelsCount(state));

    useEffect(() => {
        if (totalLabelsCount <= 0) {
            dispatch(loadLabels);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Hero cssClass="hero labels-hero" catchPhraseAtBottom={true} />

            <div className="page-container">
                <h1 className="page-title">Barcode Labels</h1>

                <NavigationTree links={GenerateNavigationTreeLinks()} />

                <div className="labels-page-content">
                    {
                        labels.map(label => {
                            return (
                                <DescriptiveProduct key={label.title} data={label} />
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default BarcodeLabels;