import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectProducts = (state: RootState) => state.products.data;

export const selectProduct = createSelector(
    selectProducts,
    (_: RootState, id: string | undefined) => id,
    (products, id) => products.find(product => product.id === id)
);

export const selectFeaturedProducts = createSelector(
    selectProducts,
    (products) => products.filter(product => product.isFeatured)
        .sort((a, b) => (a.featuredOrder ?? 0) - (b.featuredOrder ?? 1))
);

export const selectTotalProductsCount = createSelector(
    selectProducts,
    (products) => products.length
);

export const selectProductsByCategory = createSelector(
    selectProducts,
    (_: RootState, slug: string| undefined) => slug,
    (products, slug) => {

        return products
            .filter(product => product.category?.slug === slug)
            .sort((a, b) => {
                if (a.isFeatured && !b.isFeatured) {
                    return -1;
                } else if (!a.isFeatured && b.isFeatured) {
                    return 1;
                } else if (a.isFeatured && b.isFeatured) {
                    return (a.featuredOrder ?? 0) - (b.featuredOrder ?? 1);
                } else {
                    return (a.name ?? "").localeCompare(b.name ?? "");
                }
            })
    }
);