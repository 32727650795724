import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductModel } from "../../models/product-model";

interface ProductsState {
    data: ProductModel[];
}

const initialState: ProductsState = {
    data: []
};

const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        addProducts: (state: ProductsState, data: PayloadAction<ProductModel[]>) => {
            state.data = data.payload;
        }
    }
});

export const { addProducts } = productsSlice.actions;

export default productsSlice.reducer;
