import { NavLink, useLocation } from 'react-router-dom';
import './menu.css'
import { useEffect, useRef, useState } from 'react';


const isPrefixActive = (prefix: string, currentRoute: string): boolean => {
    return currentRoute?.indexOf(prefix) > -1 ?? false;
}

function Menu() {
    const [currentMenuIndex, setMenuIndex] = useState(0);
    let location = useLocation();

    // these variables affects only small devices like phones and tablets
    // Normal screens like laptops and PCs are not affected.
    const [toggleMenu, setToggleMenu] = useState(false);
    const menuRef = useRef<any>(null);

    useEffect(() => {

        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setToggleMenu(false);
            }
        }

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [toggleMenu]);

    return (
        <div ref={menuRef} className="menu-wrapper">
            <button type="button" className="menu-toggle" title="Menu"
                onClick={() => setToggleMenu(!toggleMenu)}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
            </button>
            <div className={`menu-links ${toggleMenu ? 'show' : ''}`}>
                <div className="menu-item">
                    <NavLink to={`${process.env.PUBLIC_URL}/`} className="main-link"
                        onMouseEnter={() => setMenuIndex(1)}>
                        Home
                    </NavLink>
                </div>

                <div className="menu-item">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512">
                        <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
                    </svg>

                    {/* eslint-disable-next-line */}
                    <a className={`main-link cursor-default ${isPrefixActive("/hardware/", location.pathname) ? 'active' : ''}`}
                        onMouseEnter={() => setMenuIndex(2)}>
                        Hardware
                    </a>

                    <div className={`sub-menu-container ${currentMenuIndex === 2 ? 'show' : ''}`}
                        onMouseLeave={() => setMenuIndex(0)}>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/id-printers/`}>
                                ID Printers
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/receipt-printers/`}>
                                Receipt Printers
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/desktop-barcode-printers/`}>
                                Desktop Barcode Printers
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/industrial-barcode-printers/`}>
                                Industrial Barcode Printers
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/pos/`}>
                                PoS
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/mobile-computers/`}>
                                Mobile Computers
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/hand-held-scanners/`}>
                                Hand-held Scanners
                            </NavLink>
                        </div>
                        <div className="sub-menu-item">
                            <NavLink to={`${process.env.PUBLIC_URL}/hardware/desktop-scanners/`}>
                                Desktop Scanners
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="menu-item">
                    <NavLink to={`${process.env.PUBLIC_URL}/software/`} className="main-link"
                        onMouseEnter={() => setMenuIndex(4)}>
                        Software
                    </NavLink>
                </div>

                <div className="menu-item">
                    <NavLink to={`${process.env.PUBLIC_URL}/labels/`} className="main-link"
                        onMouseEnter={() => setMenuIndex(3)}>
                        Labels
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Menu;