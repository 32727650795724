import { AppDispatch } from "..";
import { addLabels } from "../redux/slices/labels-slice";
import { addProducts } from "../redux/slices/products-slice";
import { addSoftware } from "../redux/slices/software-slice";


export const loadProducts = async (dispatch: AppDispatch) => {
    const response = await fetch(`${process.env.PUBLIC_URL}/data/products.json`);

    const products = await response.json();

    dispatch(addProducts(products));
}

export const loadLabels = async (dispatch: AppDispatch) => {
    const response = await fetch(`${process.env.PUBLIC_URL}/data/labels.json`);

    const labels = await response.json();

    dispatch(addLabels(labels));
}

export const loadSoftware = async (dispatch: AppDispatch) => {
    const response = await fetch(`${process.env.PUBLIC_URL}/data/software.json`);

    const software = await response.json();

    dispatch(addSoftware(software));
}