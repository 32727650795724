import { useEffect, useState } from 'react';
import './app.css';
import { Category, Partners, SuccessStories } from './components/home';
import { OurMission, OurVision, WhoWeAre } from './components/company-profile';
import { CategoryModel } from './models/category-model';
import { fetchData } from './services/utility-service';
import { AppDispatch } from '.';
import { useDispatch } from 'react-redux';
import { loadProducts } from './services/data-service';
import { Hero } from './components/shared';


function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  useEffect(() => {
    async function loadCategories() {
      const data =
        await fetchData<CategoryModel[]>(`${process.env.PUBLIC_URL}/data/categories.json`);

      setCategories(data);
    }

    loadCategories();
  }, []);

  useEffect(() => {
    dispatch(loadProducts);
  }, [dispatch]);

  return (
    <>
      <Hero cssClass="hero home-hero">
        The <span>best</span> source for all your <span>Barcode</span> needs
      </Hero>
      <div className="page-container">

        <section>
          <h1 className="page-title">Who We Are</h1>
          <WhoWeAre />
        </section>

        <section>
          <h1 className="page-title">Our Partners</h1>
          <Partners />
        </section>
      </div>

      <section className="categories-container">
        <div className="categories-wrapper">
          {
            categories.map((category, i) =>
              <Category key={i} data={category} />)
          }
        </div>
      </section>

      <div className="page-container">
        <section>
          <h1 className="page-title">Our Mission</h1>
          <OurMission />
        </section>

        <section>
          <h1 className="page-title">Our Vision</h1>
          <OurVision />
        </section>

        <section className="final-section">
          <h1 className="page-title">Our Success Stories</h1>
          <SuccessStories />
        </section>
      </div>
    </>
  );
}

export default App;