
const splitArray = <T>(data: T[], chunk = 3): T[][] => {
    const splitData = [] as T[][];

    for (let index = 0; index < data.length; index += chunk) {
        splitData.push(data.slice(index, index + chunk));
    }

    return splitData;
}

const fetchData = async <T>(url: string): Promise<T> => {
    const response = await fetch(url);
    const data = await response.json();
    return data as T;
}


const splitTitle = (title?: string): string[] => {
    if (!title) return [];

    return title.split(' ');
}

const convertSlugToTitle = (slug?: string): string => {
    if (!slug) return '';

    const titleParts = slug.toLowerCase().split('-');

    if (titleParts.some(part => part === "hand" || part === "held")) {
        titleParts[0] = "Hand-held";
        titleParts.splice(1, 1);
    }

    if(titleParts[0] === "id"){
        titleParts[0] = "ID";
    }

    if(titleParts[0] === "pos"){
        titleParts[0] = "PoS";
    }

    return titleParts.join(' ');
}


export { splitArray, fetchData, splitTitle, convertSlugToTitle };