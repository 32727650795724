import './hero.css';
import catchPhrase from "../../../assets/images/catch-phrase.png";

interface HeroProps {
    cssClass: string,
    catchPhraseAtBottom?: boolean,
    children?: React.ReactNode
}

function Hero({ cssClass, children, catchPhraseAtBottom = false }: HeroProps) {
    return (
        <div className={`hero-container ${cssClass}`}>
            <div className="hero-wrapper">
                <h1 className="hero-text">
                    {children}
                </h1>
                <div className={`hero-catch-phrase ${catchPhraseAtBottom ? 'place-bottom' : ''}`}>
                    <img src={catchPhrase} alt="Catch Phrase" />
                </div>
            </div>
        </div>
    )
}

export default Hero;