import './footer.css'


function Footer() {
    const phones = [
        { "text": "02 27744916", "number": "0227744916" },
        { "text": "01202600030", "number": "01202600030" },
        { "text": "01288893597", "number": "01288893597" }
    ];

    const email = "gbs@gbs-co.net";
    const mapLocation = "https://goo.gl/maps/w8U8rautsXY7aYJYA";
    const facebook = "https://www.facebook.com/Global.Barcode.solution";
    const linkedIn = "https://www.linkedin.com/company/global-barcode-solution";
    const location =
        "29 Omar Bakir of Osman Ben Afan ST., Saint Fatima, Cairo, Egypt";

    return (
        <>
            <footer className="site-footer">
                <img src={`${process.env.PUBLIC_URL}/images/full-logo.png`} alt="Full Size Logo" />

                <div className="contact-info-container">
                    <div className="contact phone">
                        <div className="icon-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                            </svg>
                        </div>

                        <div className="contact-details">
                            {
                                phones.map((phone, i) =>
                                    <a key={i} href={`tel:${phone.number}`}
                                        rel="noopener noreferrer">
                                        {phone.text}
                                    </a>
                                )
                            }
                        </div>
                    </div>
                    <div className="contact">
                        <div className="icon-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                            </svg>
                        </div>

                        <a href={`mailto:${email}`} rel="noopener noreferrer">{email}</a>
                    </div>
                    <div className="contact">
                        <div className="icon-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                            </svg>
                        </div>

                        <a href={mapLocation} target="_blank" rel="noopener noreferrer">{location}</a>
                    </div>
                </div>

                <div className="social-media-wrapper">
                    <div className="media">
                        <a href={facebook} title="GBS - Facebook" target="_blank"
                            rel="noopener noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                            </svg>
                        </a>
                    </div>
                    <div className="media">
                        <a href={linkedIn} title="GBS - LinkedIn" target="_blank"
                            rel="noopener noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </footer>
            <div className="copyright-container">
                &copy; 2023 Global <span>Barcode</span> Solutions. All rights reserved.
            </div>
        </>
    )
}


export default Footer;