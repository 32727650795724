import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DescriptiveProductModel } from "../../models/descriptive-product-model";

interface LabelsState {
    data: DescriptiveProductModel[];
}

const initialState: LabelsState = {
    data: []
};

const labelsSlice = createSlice({
    name: "labels",
    initialState,
    reducers: {
        addLabels: (state: LabelsState, data: PayloadAction<DescriptiveProductModel[]>) => {
            state.data = data.payload;
        }
    }
});

export const { addLabels } = labelsSlice.actions;

export default labelsSlice.reducer;
