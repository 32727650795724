import { useEffect, useState } from 'react';
import './partners.css'
import { PartnerModel } from '../../../models/partner-model';
import { fetchData, splitArray } from '../../../services/utility-service';


function Partners() {
    const [partners, setPartners] = useState<PartnerModel[][]>([]);
    const [currentRowIndex, setCurrentRowIndex] = useState(-1);
    // const [maxPartnerIndex, setMaxPartnerIndex] = useState(0);

    useEffect(() => {
        async function getPartners() {
            const partners
                = await fetchData<PartnerModel[]>(`${process.env.PUBLIC_URL}/data/partners.json`);

            const splitData = splitArray(partners);

            setPartners(splitData);
            setCurrentRowIndex(0);
            // setMaxPartnerIndex(splitData.length - 1);
        }

        getPartners();
    }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         let oldIndex = currentRowIndex;
    //         const newIndex = oldIndex === maxPartnerIndex ? 0 : ++oldIndex;

    //         setCurrentRowIndex(newIndex);
    //     }, 10000);

    //     return () => clearInterval(interval);
    // }, [currentRowIndex, maxPartnerIndex]);

    return (
        <div className="partners-wrapper">
            {
                partners.map((row, i) =>
                    <div className={`partners-row ${currentRowIndex === i ? 'show' : 'hide'}`} key={i}>
                        {
                            row.map((partner, y) =>
                                <div className="partner" key={y}>
                                    <a href={partner.link} rel="noopener noreferrer" target="_blank">
                                        <img loading="lazy"
                                            src={`${process.env.PUBLIC_URL}/${partner.imageUrl}`}
                                            alt={partner.title} />
                                    </a>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div >
    )
}


export default Partners;