import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../..';
import { Hero, NavigationTree, NotFound, Product } from '../../components/shared';
import { selectProductsByCategory, selectTotalProductsCount } from '../../redux/selectors/products-selector';
import { RootState } from '../../redux/store';
import { loadProducts } from '../../services/data-service';
import './hardware.css';
import { useParams } from 'react-router-dom';
import { NavigationLinkModel } from '../../models/navigation-link-model';
import { convertSlugToTitle } from '../../services/utility-service';


const generateNavigationTreeLinks =
    (pageTitle: string): NavigationLinkModel[] => {
        const homePageNavigationModel = {
            title: 'Home',
            url: ''
        } as NavigationLinkModel;

        return [
            homePageNavigationModel,
            {
                title: pageTitle,
                url: null
            }
        ] as NavigationLinkModel[];
    }

const generateTitle = (slug?: string): string => {
    if (!slug) return 'Hardware';

    return convertSlugToTitle(slug);
}

function Hardware() {
    const { slug } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const pageTitle = generateTitle(slug);

    const products =
        useSelector((state: RootState) => selectProductsByCategory(state, slug));

    const totalProductsCount = useSelector((state: RootState) => selectTotalProductsCount(state));

    useEffect(() => {
        if (totalProductsCount <= 0) {
            dispatch(loadProducts);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Hero cssClass="hero home-hero" catchPhraseAtBottom={true} />
            
            <div className={`page-container ${products.length < 4 ? 'page-container-margin-bottom' : null}`}>
                <h1 className="page-title">{pageTitle}</h1>

                <NavigationTree links={generateNavigationTreeLinks(pageTitle)} />

                {
                    products.length > 0 &&
                    <div className="products-wrapper">
                        {
                            products.map((product, i) =>
                                <Product key={`${slug}-${i}`} data={product} />)
                        }
                    </div>
                }

                {
                    products.length <= 0 &&
                    <NotFound text="Product Category Not Found" />
                }
            </div>
        </>
    )
}

export default Hardware;
