import { useParams } from 'react-router-dom';
import './product-details.css'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { selectProduct, selectTotalProductsCount } from '../../redux/selectors/products-selector';
import { DetailsSection } from '../../components/product-details';
import { useEffect } from 'react';
import { AppDispatch } from '../..';
import { loadProducts } from '../../services/data-service';
import { NavigationLinkModel } from '../../models/navigation-link-model';
import { Hero, NavigationTree } from '../../components/shared';
import { ProductModel } from '../../models/product-model';
import { convertSlugToTitle } from '../../services/utility-service';

const GenerateNavigationTreeLinks =
    (currentProduct?: ProductModel): NavigationLinkModel[] => {
        const homePageNavigationModel = {
            title: 'Home',
            url: ''
        } as NavigationLinkModel;

        const categoryTitle = convertSlugToTitle(currentProduct?.category?.slug);

        return [
            homePageNavigationModel,
            {
                title: categoryTitle,
                url: `hardware/${currentProduct?.category?.slug}`
            },
            {
                title: currentProduct?.name,
                url: null
            }
        ] as NavigationLinkModel[];
    }


function ProductDetails() {
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const product = useSelector((state: RootState) => selectProduct(state, id));
    const productsCount = useSelector((state: RootState) => selectTotalProductsCount(state));

    useEffect(() => {
        if (productsCount <= 0) {
            dispatch(loadProducts);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Hero cssClass="hero home-hero" catchPhraseAtBottom={true} />

            <div className="page-container">
                {<h1 className="page-title">{product?.name}</h1>}

                <NavigationTree links={GenerateNavigationTreeLinks(product)} />

                <div className="general-info">
                    <img src={`${process.env.PUBLIC_URL}/${product?.imageUrl}`} alt={product?.name} />
                    <p>{product?.description}</p>
                </div>

                <div className="product-details-container">
                    {
                        product?.specs?.map((item, index) =>
                            <div key={`p_${index}`} className="product-details-item">
                                <DetailsSection section={item} />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default ProductDetails;