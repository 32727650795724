import './who-we-are.css'

function WhoWeAre() {
    return (
        <div className="about-us-container">
            <p>
                <span>GBS</span> is an Egyptian company which was established 2013 with a leading market share
                nowadays providing integrated Barcode solutions & low-current systems.
                We provide our clients integrated Automatic Identification and Data Capture (AIDC)
                smart solutions which have proven distinguished results in the fields of retail,
                warehousing, order entry, manufacturing, operation control, route accounting,
                document tracking, product tracking, asset tracking, attendance system, in addition to
                providing their consumables as direct thermal and direct transfer labels with ribbons
                and receipt rolls.
            </p>
            <p>
                <span>GBS</span> experienced team ready for tailoring digital services which is
                comprehensive and customized as per clients' needs. Our engineering
                consultants design customized solutions to provide cost-efficient and
                advanced technology featuring an easy-to-use human interface. Agile and
                responsive, the GBS team combines knowledge, experience, and passion to ensure
                that each project is delivered on time, on budget, according to specific
                quality standards, with personalized customer service.
            </p>
            <p>
                At <span>GBS</span>, we encourage our clients to implement digital solutions to
                facilitate process management, optimize cost and increase revenue.
            </p>
        </div>
    )
}


export default WhoWeAre;