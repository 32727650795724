import { NavLink, NavigateFunction, useNavigate } from "react-router-dom";



interface NavigateProps {
    to: string;
    state?: any;
    className?: string;
    children?: React.ReactNode;
}

const getRelativeUrl = (url: string) => {
    const relativeUrl = url.replace(window.location.origin, '');

    return relativeUrl;
}

const onNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    navigate: NavigateFunction, state: any) => {
    e.stopPropagation();
    e.preventDefault();

    const relativeUrl = getRelativeUrl(e.currentTarget.href);



    navigate(relativeUrl, { state });
    window.scrollTo(0, 0);
}

function Navigate({ to, state, className, children }: NavigateProps) {
    const navigation = useNavigate();

    return (
        <NavLink to={to} className={className} state={state}
            onClick={(evt) => onNavigate(evt, navigation, state)}>
            {children}
        </NavLink>
    );
}

export default Navigate;