import './header.css'
import Menu from '../menu/menu';
import { Link } from 'react-router-dom';



function Header() {
    return (
        <header className="site-header">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Site Logo" />
            </Link>
            <div className="menu-container">
                <Menu />
            </div>
        </header>
    )
}


export default Header;