import { DescriptiveProductModel } from '../../../models/descriptive-product-model';
import './descriptive-product.css';

interface DescriptiveProductProps {
    data: DescriptiveProductModel
}

function descriptiveProduct({ data }: DescriptiveProductProps) {
    return (
        <div className="descriptive-product">
            <h2 className="product-title">{data.title}</h2>
            <div className="divider">
                <img src={`${process.env.PUBLIC_URL}/${data.imageUrl}`} alt={data.title} />
                <p>{data.description}</p>
            </div>
        </div>
    );
}


export default descriptiveProduct;