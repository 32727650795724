import './our-vision.css'

function OurVision() {
    return (
        <div className="our-vision">
            To penetrate the <span>Middle East</span> market through providing AIDC integrative solutions for diversified business sectors.
        </div>
    )
}

export default OurVision;