import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts.css';
import './index.css';
import App from './app';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Footer, Header } from './components/common';
import { BarcodeLabels, Hardware, ProductDetails, Software } from './pages';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export type AppDispatch = typeof store.dispatch;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Header />

        <main>
          <Routes>
            {/* Home page */}
            <Route path={process.env.PUBLIC_URL} Component={App} />

            {/* Hardware Category page */}
            <Route path={`${process.env.PUBLIC_URL}/hardware/:slug`} Component={Hardware} />

            {/* Software page */}
            <Route path={`${process.env.PUBLIC_URL}/software`} Component={Software} />

            {/* Barcode Labels page */}
            <Route path={`${process.env.PUBLIC_URL}/labels`} Component={BarcodeLabels} />

            {/* Product Details page */}
            <Route path={`${process.env.PUBLIC_URL}/hardware/product-details/:id`} Component={ProductDetails} />
          </Routes>

        </main>

        <Footer />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
