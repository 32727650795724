import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./slices/products-slice";
import labelReducer from "./slices/labels-slice";
import softwareReducer from "./slices/software-slice";

const store = configureStore({
    reducer: {
        products: productReducer,
        labels: labelReducer,
        software: softwareReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export default store;