import './our-mission.css'


function OurMission() {
    return (
        <div className="our-mission">
            Providing <span>our clients</span> comprehensive integrated digital solutions for increasing the 
            efficiency and effectiveness of process, as well as providing full control over supply 
            chain operations to achieve business goals. We provide quality products, high ethical 
            standards, world-class technology, technical consultancy and passion for innovation.
        </div>
    )
}

export default OurMission;