import './not-found.css';

interface NotFoundProps {
    text?: string;
}

function NotFound({ text }: NotFoundProps) {
    return (
        <div className="not-found-container">
            <label>{text || "Not Found"}</label>
        </div>
    )
}

export default NotFound;