import { ProductDetailsModel, ProductDetailsSectionModel } from '../../../models/product-model';
import './details-section.css';

interface DetailsSectionProps {
    section: ProductDetailsModel;
}

const cellContentCss = (data?: ProductDetailsSectionModel): string => {
    return data?.values && data.values.length > 1
        ? "details-section-data-content-list"
        : "details-section-data-content-item"
}

function DetailsSection({ section }: DetailsSectionProps) {

    return (
        <>
            <h3>{section.title}</h3>
            <div className="details-section-data-table">
                {
                    section?.content?.map((data, index) =>
                        <div key={index} className="details-section-data-row">
                            <div className="details-section-data-header">
                                {data?.header?.toLowerCase()}
                            </div>
                            <div className="details-section-data-content">
                                {
                                    data?.values?.map((value, cIndex) =>
                                        <div key={cIndex}
                                            className={cellContentCss(data)}>
                                            {value}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default DetailsSection;