import { Navigate } from '..';
import { NavigationLinkModel } from '../../../models/navigation-link-model';
import './navigation-tree.css'

interface NavigationTreeProps {
    links: NavigationLinkModel[];
}


const drawNavigationLink = (link: NavigationLinkModel, index: number) => {
    if (link.url === null) {
        return (
            <div className="navigation-link unlinked" key={index}>
                {link.title}
            </div>
        )
    }

    return (
        <Navigate key={index} to={`${process.env.PUBLIC_URL}/${link.url}`}
            className="navigation-link" state={link.state}>
            {link.title}
        </Navigate>
    )
}


function NavigationTree({ links }: NavigationTreeProps) {
    return (
        <div className="navigation-tree-container">
            <div className="navigation-links-wrapper">
                {
                    links.map(drawNavigationLink)
                }
            </div>
        </div>
    )
}

export default NavigationTree;